import React, { useState, useEffect } from "react";
import Select from "react-select";
import Translator from "@/components/Translator/Translator";

const LanguageSelect = () => {
    // Estado para almacenar el lenguaje seleccionado
    const [language, setLanguage] = useState({ value: "en", label: "English" });

    // Función para manejar el cambio de lenguaje
    const handleLanguageChange = (selectedOption) => {
        const selectedLanguage = selectedOption.value;
        setLanguage(selectedOption);
        if (typeof window !== 'undefined') {
            localStorage.setItem('neotel.us_t_language', selectedLanguage); // Guarda el valor seleccionado en localStorage si se está ejecutando en el navegador
            const _T = new Translator();
            _T.initialize();
            console.log(_T)
        }
    };

    // Efecto para cargar el lenguaje inicial desde localStorage (solo en el cliente)
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const storedLanguage = localStorage.getItem('neotel.us_t_language');
            if (storedLanguage) {
                const label = LanguageOptions.find(x => x.value == storedLanguage).label;
                const selected = { value: storedLanguage, label: label }
                setLanguage(selected);
            }
        }
    }, []);

    // Opciones de lenguaje
    const LanguageOptions = [
        { value: "en", label: "English" },
        { value: "es", label: "Español" },
        { value: "po", label: "Português" },
    ];

    return (
        <>
            <span>
                <div className="header-btn">
                    <div className="rbt-modern-select bg-transparent" style={{ position: 'relative' }}>
                        <Select
                            className="bootstrap-select-"
                            classNamePrefix="bootstrap-select"
                            value={language}
                            onChange={handleLanguageChange}
                            options={LanguageOptions}
                            closeMenuOnSelect={true}
                            menuPosition="fixed"
                        />
                    </div>
                </div>
            </span>
        </>
    );
};

export default LanguageSelect;