/**
 * s Version: 1.0.0
 */
// interface IResource { key: string, value: string, tooltip: string };
// export interface IResources { language: string, resources: Array<IResource> | null };
// export interface IOptions { user: string, languaje: IResources };
import { es_resources, en_resources, po_resources } from "./Resources";

export default class Translator {
    options = { user: "neotel.us", resources: en_resources };
    resources = null;

    constructor() {
        
        let currentLanuage = localStorage.getItem(`neotel.us_t_language`)
        if (!currentLanuage)
            currentLanuage = this.getBrowserLocales({ languageCodeOnly: true })[0];

        localStorage.setItem(`neotel.us_t_language`, currentLanuage);
        switch (currentLanuage.toLowerCase()) {
            case "es":
                this.resources = es_resources;
                break;
            case "en":
                this.resources = en_resources;
                break;
            case "po":
                this.resources = po_resources;
                break;
        }

    }

    initialize(controlTypes = ["*"]) {
        /* controlTypes es un array de selectores de Jquery */
        for (let i = 0; i < controlTypes.length; i++) {
            /* me aseguro que solo tome los tags con el atributo tkey */
            controlTypes[i] = controlTypes[i] + "[tkey]";
            const controls = document.querySelectorAll(controlTypes[i]);
            controls.forEach((control) => {
                if (control.getAttribute('tkey') != undefined) {
                    const tkey = control.getAttribute("tkey")?.toString();
                    const jsonKey = (tkey != undefined ? JSON.parse(tkey) : false);
                    if (jsonKey && Array.isArray(jsonKey))
                        jsonKey.forEach((value) => {
                            this.setTranslated(control, value);
                        });
                }
            });
        }
    };

    translateSection(controlSelector) {
        setTimeout(() => {
            this.initialize(controlSelector);
        }, 200);
    };
    /**
     *
     * @param {*} control Control html jQuery $(jQuerySelector)
     * @param {*} array [["htmlAtribute","value"]] or [["htmlAtribute",["value",param1, param2, paramN]]]
     *
     * Ejemplo :
     *      ["value","25"]
     *      ["html","Cantidad"]
     *      ["title","Field's description"]
     *      ["html",["price","$12"]]
     */
    setTranslated(control, array) {
        const translateTo = array[0];
        const keyArray = array[1];
        const translation = this.translate(keyArray);
        if (!translation) return;
        switch (translateTo.toLowerCase()) {
            case "html":
                control.innerHTML = translation;
                break;
            case "value":
                control.value = "";
                break;
            default:
                control.setAttribute(translateTo, "");
                break;
        }
    };

    /**
 * Recive un string y devuelve su traduccion (siempre que este dentro de los recursos)
 * @param key 
 * @returns 
 */
    translate(key) {
        const ret = this.translateKey(key);
        if (ret.value != "") {
            return ret.value;
        } else {
            return ret.key;
        }
    }

    /**
     *
     * @param {*} key Array =>  ['key','param1','param2','paramN']
     *
     *  Ejemplo 1:
     *      Recibe:  ["NeoIntranet"]
     *      Busca:   { "Key": "NeoIntranet", "value": "Bienvenido a la intranet de Neotel", "tooltip": "Mensaje de bienvenida" }
     *      Retorna: { "Key": "NeoIntranet", "value": "Bienvenido a la intranet de Neotel", "tooltip": "Mensaje de bienvenida" }
     *  Ejemplo 2:
     *      Recibe:  ['INTDATA','Precio']
     *      Busca:   { "Key": "INTDATA","value": "{0} debe ser un número entero.","tooltip": "Validación precio"}
     *      Retorna: { "Key": "INTDATA","value": "Precio debe ser un número entero.","tooltip": "Validación precio"}
     *  Ejemplo 3:
     *      Recibe:  ['LengthFieldValidatorMessage','Cliente','0','100'] =>
     *      Busca:   { "Key": "LengthFieldValidatorMessage","value": "El campo {0} no respeta el largo mínimo o máximo ( {1} / {2} )","tooltip":"Validación largo campo." }
     *      Retorna: { "Key": "LengthFieldValidatorMessage","value": "El campo Cliente no respeta el largo mínimo o máximo ( 0 / 100 ).","tooltip":"Validación largo campo." }
     *
     */
    translateKey(key) {

        if (key != undefined && key != "") {
            try {
                let arrKey = new Array();
                try {
                    arrKey = JSON.parse(key);
                } catch (ex) {
                    /* Si no es un json, puede ser un array real o una clave simple. */
                    if (Array.isArray(key) == false) {
                        arrKey.push(key);
                    } else {
                        arrKey = key;
                    }
                }
                let retKey = { key, value: key };

                let retValue = key;
                if (this.resources && this.resources?.length >= 1) {
                    this.resources?.forEach((resourceValue) => {
                        if (resourceValue.Key.toLowerCase() == arrKey[0].toLowerCase()) {
                            if (arrKey.length > 1) {
                                retKey.key = resourceValue.Key;
                                retValue = resourceValue.Value;
                                arrKey.forEach((value, index) => {
                                    if (value != resourceValue.Key) {
                                        retKey.value = retValue = retValue.replace(
                                            "{" + (index - 1) + "}",
                                            this.translate(value)
                                        );
                                    }
                                });
                            } else {
                                retKey.value = resourceValue.Value != "" ? resourceValue.Value : key;
                                return false;
                            }
                        }
                    });
                }
                return retKey;
            } catch (ex) {
                console.error("Error on Translator.translateKey:", ex.message);
                return key;
            }
        }
        return key;
    };

    getBrowserLocales(options = {}) {
        const defaultOptions = {
            languageCodeOnly: false,
        };
        const opt = {
            ...defaultOptions,
            ...options,
        };
        const browserLocales =
            navigator.languages === undefined
                ? [navigator.language]
                : navigator.languages;
        if (!browserLocales) {
            return undefined;
        }
        return browserLocales.map(locale => {
            const trimmedLocale = locale.trim();
            return opt.languageCodeOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale;
        }).filter((value, index) => browserLocales.indexOf(value) === index);
    }
    //#region DEBUG

    debugKeys(controlTypes = ["*"]) {
        /* controlTypes es un array de selectores de Jquery */
        for (let i = 0; i < controlTypes.length; i++) {
            /* me aseguro que solo tome los tags con el atributo tkey */
            controlTypes[i] = controlTypes[i] + "[tkey]";
            const controls = document.querySelectorAll(controlTypes[i]);
            controls.forEach((control) => {
                if (control.getAttribute('tkey') != undefined) {
                    const tkey = control.getAttribute("tkey")?.toString();
                    const jsonKey = (tkey != undefined ? JSON.parse(tkey) : false);
                    if (jsonKey && Array.isArray(jsonKey))
                        jsonKey.forEach((value) => {
                            this.setTranslated(control, value);
                            const keyArray = value[1];
                            this._translate(keyArray, control);
                        });
                }
            });
        }
    };
    /**
     * Llama internamente a la funcion translate, pero solo remarca en pantalla las claves faltantes.
     * @param key 
     * @returns 
     */
    _translate(key, control) {
        const translation = this.translateKey(key);
        if (translation.key == key) {
            control.style.background = "red"
            control.style.color = "white";
        }
    }
    //#endregion DEBUG
}


